
import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
export default (props) => {

    const [isMyInputFocused, setIsMyInputFocused] = useState(false);

    const formatter = new Intl.NumberFormat('sfb', {
        style: 'currency',
        currency: 'EUR'
    })
    // const [prijs, plan] = useState(true)
    return (                    
        <div css={[tw`w-40 bg-white rounded-lg shadow-md p-6 cursor-pointer mb-8 hover:bg-yellow-100 focus:outline-none`, props.active && tw`shadow-outline-yellow`]} onClick={props.onClick} onFocus={() => setIsMyInputFocused(true)} tabIndex="0">
            <div tw="flex justify-between items-center mb-3">
            <h1 tw="text-base tracking-wide my-2 mr-2">Maaltijdbox</h1>
            {props.active && 
                <svg tw="w-6 h-6" width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="checkmark-outline" fillRule="nonzero">
                            <path d="M31.1442786,171.840796 C5.2779518,146.858262 -5.09578082,109.862896 4.01023318,75.0738981 C13.1162472,40.2848999 40.2848999,13.1162472 75.0738981,4.01023318 C109.862896,-5.09578082 146.858262,5.2779518 171.840796,31.1442786 C209.549474,70.1869539 209.010186,132.247241 170.628714,170.628714 C132.247241,209.010186 70.1869539,209.549474 31.1442786,171.840796 Z" id="Shape" fill="#ecc94b"></path>
                            <polygon id="Path" fill="#ffffff" points="66.6666667 89.4527363 89.5522388 112.437811 132.338308 69.6517413 146.268657 83.7810945 89.5522388 140.298507 52.7363184 103.482587 66.6666667 89.3532338"></polygon>
                        </g>
                    </g>
                </svg>
            }
                
            
            </div>

            <div tw="mb-1 font-semibold ">
            <span tw="text-5xl mr-2">{props.plan}</span>
            {/* <span tw="text-2xl">GB</span> */}
            </div>

            <div>
            <span tw="text-xl ">{formatter.format(props.prijs * props.count)}</span>
            {/* <span tw="text-xl font-semibold ">TEST</span> */}
            <span tw="mx-1">/</span>
            <span tw="text-lg ">box</span>
            </div>
        </div>
    );
}