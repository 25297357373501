import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/ThreeColWithSideImage.js";
// import MainFeature from "./components/features/TwoColWithButton.js";
import MainFeature from "./components/features/TwoColSingleFeatureWithStats2.js"
import MainFeature2 from "./components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
// import Pricing from "./components/pricing/ThreePlans.js";
import Pricing from "./components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "./components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStarted";
import SubscribeNewsLetterForm from "./components/forms/SimpleSubscribeNewsletter.js"
import Footer from "./components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "./images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "./images/bijdrage.svg";
import prototypeIllustrationImageSrc from "./images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/stop-circle.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/check-circle.svg"
import HistoryOverview from "./components/donations/HistoryOverview.js";
import BetalingSuccesvol from "./components/hero/BetalingGelukt.js";
import BetalingMislukt from "./components/hero/BetalingMislukt.js";
import BetalingGelukt from "./components/hero/BetalingGelukt.js";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>      
      <BetalingGelukt/>
      <SubscribeNewsLetterForm />
      <Footer />
    </AnimationRevealPage>
  );
};
