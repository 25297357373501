import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/ThreeColWithSideImage.js";
// import MainFeature from "./components/features/TwoColWithButton.js";
import MainFeature from "./components/features/TwoColSingleFeatureWithStats2.js"
import MainFeature2 from "./components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
// import Pricing from "./components/pricing/ThreePlans.js";
import Pricing from "./components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "./components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStarted";
import SubscribeNewsLetterForm from "./components/forms/SimpleSubscribeNewsletter.js"
import Footer from "./components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "./images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "./images/bijdrage.svg";
import prototypeIllustrationImageSrc from "./images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/stop-circle.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/check-circle.svg"

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const [donations, setDonations] = useState([]);
  useEffect( () => {
      async function fetchData() {
        const res = await fetch(`/api/getOrders`).then(response => response.json())
        setDonations(res)
      }
      fetchData();
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <MainFeature donations={donations} />
      <FeatureWithSteps
        subheading={<Subheading>STAPPEN</Subheading>}
        heading={
          <>
            Eenvoudig <HighlightedText>bijdragen.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>WAARDEN</Subheading>}
        heading={
          <>
            We houden ons altijd aan onze <HighlightedText>principes.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Traceerbaar",
            description: "Dankzij de blockchain technologie kunnen donateurs hun en alle andere donaties realtime opvolgen.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Onveranderlijk",
            description: "Elke donatie en uitgave wordt rechtsreeks weggeschreven in het blockchain netwerk waarin deze onveranderlijk blijft staan.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <Pricing 
        id="pricing"
        heading={<>Onze <HighlightedText>Maaltijdboxen</HighlightedText></>}
      />
      <Testimonial
        subheading={<Subheading>Getuigenissen</Subheading>}
        heading={
          <>
            Gezinnen <HighlightedText>danken u.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "#",
            heading: "Wij zijn dankbaar",
            quote:
              "\"Het leven is moeilijk geweest voor ons. Alle hulp is daarom uiteraard zeer welkom. Het is fijn om te zien dat nog steeds veel mensen zich bekommeren om de minderbeelden. We zijn hun meer dan dankbaar voor.\"",
            customerName: "- Anoniem",
            customerTitle: ""
          },
          {
            stars: 5,
            profileImageSrc:
              "#",
            heading: "Een moeilijk jaar geweest",
            quote:
              "\"Het is een moeilijk jaar geweest voor veel Vlamingen. Een gezin onderhouden zonder inkomen is niet evident. Het is steeds stressen of we een volgende maaltijd zullen hebben.\"",
            customerName: "- Anoniem",
            customerTitle: ""
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>Veelgestelde vragen</Subheading>}
        heading={
          <>
            U hebt <HighlightedText>vragen ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Hoe werkt de organisatie?",
            answer:
              "Beegiving is een Vlaams donatieplatform dat samen werkt met vrijwilligers en lokale vzw's over heel Vlaanderen om maaltijdboxen te leveren aan gezinnen in kwetsbare gemeenschappen. Donateurs kunnen kiezen om een of meteen meerdere gezinnen te steunen met behulp van een Maaltijdbox. "
          },
          {
            question: "Wat houdt een maaltijdbox juist allemaal in?",
            answer:
              "Onze maaltijdboxen bevatten volgende basisbehoeften: melk, rijst, pasta, olie, pastasaus en suiker. Daarbovenop voorzien we ook wekelijks iets extra voor de kinderen in elke Maaltijdbox zoals chocopasta, koekjes, confituur, cornflakes, etc."
          },
          {
            question: "Welke betaalmethoden accepteren jullie?",
            answer:
              "Donaties dienen allemaal via Beegiving.be-website te gebeuren. We accepteren Visa, MasterCard, Bankcontact en PayPal."
          },
          {
            question: "Hoe kan ik mijn donatie opvolgen?",
            answer:
              "Alle donaties zijn meteen opgeslagen in ons Blockchain-netwerk. Deze donaties en alle uitgaven kunnen vervolgens geraadpleegd worden op onze transacties-pagina."
          },
          {
            question: "Worden de donaties integraal gebruikt voor het leveren van maaltijdboxen ?",
            answer:
              "De donaties worden integraal gebruikt voor het aankoop van de maaltijdboxen. Het transport en de verdeling hiervan gebeurt door vrijwilligers en lokale vzw's. U donaties zal voor 100% gaan naar de gezinnen die het nodig hebben."
          },
          {
            question: "Kunnen mensen zich aanmelden om een maaltijdbox te ontvangen ?",
            answer:
              "Indien u, of gezinnen die u kent, zich willen aanmelden voor maaltijdboxen, kan u contact opnemen met ons via het contactformulier op onze website. Wij zullen u dan zo spoedig mogelijk verder helpen; rechtsreeks of via een lokale vzw."
          }
        ]}
      />
      <SubscribeNewsLetterForm />
      <Footer />
    </AnimationRevealPage>
  );
};
