import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "../../images/svg-decorator-blob-6.svg";
import Header from "../headers/light.js"

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

const formatter = new Intl.NumberFormat('sfb', {
  style: 'currency',
  currency: 'EUR'
})
const dateTimeFormat = new Intl.DateTimeFormat('nl', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

export default ({ historicalDonationData, roundedHeaderButton, heading, subheading, description }) => {

  return (
    <Container>
      <Header roundedHeaderButton={roundedHeaderButton} />

      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {/* {plans.map((plan, index) => ( */}
            
          {/* ))} */}
          
          <>
          <div tw="bg-white shadow-sm w-full  top-0 ">
                <div tw="bg-white w-full top-0 ">
                  <div tw="py-12 bg-white">
                    <div tw="flex justify-around items-stretch flex-col md:flex-row text-center">
                      <Subheading tw="text-sm text-primary-500 font-bold my-4">Aantal donaties:<br/><span tw="font-mono font-black text-secondary-500 text-3xl">{historicalDonationData.length}</span></Subheading>
                      <Subheading tw="text-sm text-primary-500 font-bold my-4">Totaal ingezameld:<br/><span tw="font-mono font-black text-secondary-500 text-3xl">{formatter.format(historicalDonationData.reduce(function(total, d) { return d.totaal+total}, 0))}</span></Subheading>
                      <Subheading tw="text-sm text-primary-500 font-bold my-4">Maaltijdboxen gedoneerd:<br/><span tw="font-mono font-black text-secondary-500 text-3xl">{historicalDonationData.reduce(function(total, d) { return d.aantal+total}, 0)}</span><span tw="text-sm font-light text-secondary-500"> / 500.000</span></Subheading>
                    </div>
                  </div>
                </div>
          
          <div tw="flex flex-col bg-white m-auto justify-around ">
                <h1 tw="text-2xl font-light px-10 py-5 object-center">Historiek:</h1>
                <div tw="flex overflow-x-scroll pb-10 justify-around">
                  <div tw="flex flex-no-wrap  flex-col justify-around">
                    {historicalDonationData.reverse().map((donation, index) => 
                      <>
                        <div tw="inline-block my-3">
                          <div
                            tw=" px-4 py-4 bg-white w-72 md:w-128 h-40 max-w-lg overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                          >
                          
                          <div tw="text-center mt-2 leading-none flex justify-between w-full">
                              <h3 tw="py-2 text-2xl md:text-3xl font-bold font-mono">+ {formatter.format(donation.totaal)}</h3>
                              <span tw=" inline-flex items-center leading-none text-sm">
                                #{historicalDonationData.length - index}
                              </span>
                          </div>

                          <div tw="text-left mt-2 leading-none flex justify-between w-full">
                            <p tw="text-xs">Aantal maaltijdboxen: {donation.aantal}<br/>Tijdstip: {dateTimeFormat.format(new Date(donation.createdAt))}</p>
                            
                          </div>
                          <div tw="text-left mt-4 leading-none flex justify-between w-full">
                            <p tw="text-xs">ID: {donation.orderId} <br/> door {donation.name}</p>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Bancontact_logo.svg" className="logo-area" tw="h-4 mt-2"/>
                          </div>

                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
            </div>
    </div>
          </>
          <DecoratorBlob/>
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
