import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/ThreeColWithSideImage.js";
// import MainFeature from "./components/features/TwoColWithButton.js";
import MainFeature from "./components/features/TwoColSingleFeatureWithStats2.js"
import MainFeature2 from "./components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
// import Pricing from "./components/pricing/ThreePlans.js";
import Pricing from "./components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "./components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStarted";
import SubscribeNewsLetterForm from "./components/forms/SimpleSubscribeNewsletter.js"
import Footer from "./components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "./images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "./images/bijdrage.svg";
import prototypeIllustrationImageSrc from "./images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/stop-circle.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/check-circle.svg"
import Header from "./components/headers/light.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const [donations, setDonations] = useState([]);
  useEffect( () => {
      async function fetchData() {
        const res = await fetch(`/api/getOrders`).then(response => response.json())
        setDonations(res)
      }
      fetchData();
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Header roundedHeaderButton={true} />
      <FAQ
        subheading={<Subheading>Veelgestelde vragen</Subheading>}
        heading={
          <>
            U hebt <HighlightedText>vragen ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Hoe werkt de organisatie?",
            answer:
              "Beegiving is een Vlaams donatieplatform dat samen werkt met vrijwilligers en lokale vzw's over heel Vlaanderen om maaltijdboxen te leveren aan gezinnen in kwetsbare gemeenschappen. Donateurs kunnen kiezen om een of meteen meerdere gezinnen te steunen met behulp van een Maaltijdbox. "
          },
          {
            question: "Wat houdt een maaltijdbox juist allemaal in?",
            answer:
              "Onze maaltijdboxen bevatten volgende basisbehoeften: melk, rijst, pasta, olie, pastasaus en suiker. Daarbovenop voorzien we ook wekelijks iets extra voor de kinderen in elke Maaltijdbox zoals chocopasta, koekjes, confituur, cornflakes, etc."
          },
          {
            question: "Welke betaalmethoden accepteren jullie?",
            answer:
              "Donaties dienen allemaal via Beegiving.be-website te gebeuren. We accepteren Visa, MasterCard, Bankcontact en PayPal."
          },
          {
            question: "Hoe kan ik mijn donatie opvolgen?",
            answer:
              "Alle donaties zijn meteen opgeslagen in ons Blockchain-netwerk. Deze donaties en alle uitgaven kunnen vervolgens geraadpleegd worden op onze transacties-pagina."
          },
          {
            question: "Worden de donaties integraal gebruikt voor het leveren van maaltijdboxen ?",
            answer:
              "De donaties worden integraal gebruikt voor het aankoop van de maaltijdboxen. Het transport en de verdeling hiervan gebeurt door vrijwilligers en lokale vzw's. U donaties zal voor 100% gaan naar de gezinnen die het nodig hebben."
          },
          {
            question: "Kunnen mensen zich aanmelden om een maaltijdbox te ontvangen ?",
            answer:
              "Indien u, of gezinnen die u kent, zich willen aanmelden voor maaltijdboxen, kan u contact opnemen met ons via het contactformulier op onze website. Wij zullen u dan zo spoedig mogelijk verder helpen; rechtsreeks of via een lokale vzw."
          }
        ]}
      />
      {/* <GetStarted/> */}
      <SubscribeNewsLetterForm />
      <Footer />
    </AnimationRevealPage>
  );
};
