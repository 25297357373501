import React from "react";
import tw from "twin.macro";
import {ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg"
import {Container, ContentWithPaddingXl } from "../../components/misc/Layouts.js"
// import { ContentWithPaddingXl, Container } from "../../components/misc/Layouts"
import {SectionHeading} from "../../components/misc/Headings.js";
import {PrimaryButton} from "../../components/misc/Buttons.js";


// const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-primary-500 rounded-lg relative`

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-white`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-white tracking-wider font-bold border border-white focus:border-yellow-100 focus:outline-none sm:rounded-r-none transition duration-300 text-gray-600`
const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-black text-gray-100 hocus:bg-yellow-900 hocus:text-gray-300 border border-black hocus:border-yellow-900`


export default () => {
  return (
    <Container css={tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextColumn>
              <EmailNewsletterIcon />
              <HeadingInfoContainer>
                <Heading>Nieuwsbrief</Heading>
                <Description>Abonneer u nu om onze laatste berichten te ontvangen.</Description>
              </HeadingInfoContainer>
            </TextColumn>
            <FormColumn>
            <Form>
              
                <>
                  <div id="mc_embed_signup">
                  <form action="https://beegiving.us6.list-manage.com/subscribe/post?u=dcf1fa2d413a88c5e3730edf4&amp;id=58dbccba44" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                      <div id="mc_embed_signup_scroll">
                    
                  {/* <div class="indicates-required"><span class="asterisk">*</span> indicates required</div> */}
                  <div class="mc-field-group">
                    {/* <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span></label> */}
                    {/* <input type="email" name="EMAIL" class="required email" id="mce-EMAIL"/> */}
                    
                  </div>
                    <div id="mce-responses" class="clear">
                      <div class="response" id="mce-error-response" style={{display:'none'}}></div>
                      <div class="response" id="mce-success-response" style={{display:'none'}}></div>
                    </div>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_dcf1fa2d413a88c5e3730edf4_58dbccba44" tabindex="-1" value=""/></div>
                    <div class="clear">
                      <Input placeholder="Uw emailadres" type="email" name="EMAIL" class="required email" id="mce-EMAIL"/>
                      {/* <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/> */}
                      <Button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" >Abonneer</Button>
                    </div>
                      
                    </div>
                  </form>
                  </div>
                </>
            </Form>
            </FormColumn>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
