import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-7.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import SelectablePlan from "./SelectablePlan.js";
import {loadStripe} from '@stripe/stripe-js';
import {ReCAPTCHA} from "react-google-recaptcha";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-md sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-40 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const NumberInput = styled.input`
    ${tw`outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold hover:text-black focus:text-black flex items-center text-gray-700  outline-none`}
    ${css`
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }`}
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const StyledModal = styled(ReactModalAdapter)`
&.mainHeroModal__overlay {
  ${tw`fixed inset-0 z-50 w-full m-auto overflow-auto bg-black-transparent `}
}
&.mainHeroModal__content {
  ${tw`md:mx-auto lg:max-h-150 lg:my-auto sm:m-0 max-w-screen-md absolute inset-0 flex justify-center items-center lg:rounded-lg bg-gray-200 outline-none`}
}
.content {
  ${tw`w-full lg:p-16 opacity-100`}
}
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
const Question = tw.dt`flex justify-between items-center`
const ToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
const fetcher = (url) => fetch(url).then((res) => res.json())
const openPaymentWindow = async (price, count) => {
  const formaat = price == 3.2 ? 'KLEIN' : 'GROOT';
  const aantal =  count;
  
  fetcher(`/api/createOrder?aantal=${aantal}`)
  .then(data => {
    window.open(data.paymentUrl, "_self")
    // console.log(data); // JSON data parsed by `data.json()` call
  });

  // initialize Stripe using your publishable key
  // const stripe = Stripe('pk_test_Uczh5PNhB0aS0MZHnm2fkODM00MSoV8j3h');
  // const stripe = await loadStripe('pk_test_51JBYt9KTbujvlsSXs9vFcHRtI7kRuCuuccHcXofXHUSfQmzeHmsYZ5FfKujqpzvM1alLS5NpRG5vtGNYrOT1KPM400whJc6X13');

  // // find the button and error message elements
  // const checkoutButton = document.getElementById('donate-button');
  // const errorMessage = document.getElementById('error-message');

  // on click, send the user to Stripe Checkout to process the donation
  // checkoutButton.addEventListener('click', () => {
    // stripe
    // .confirmBancontactPayment({
      
    //   lineItems: [{ price: 'price_1JBZ03KTbujvlsSXOxLXQlEg', quantity: count }],
    //   mode: "pay"
    // })
      // .redirectToCheckout({
      //   lineItems: [{ price: 'price_1JBZ03KTbujvlsSXOxLXQlEg', quantity: count }],
      //   mode: "payment",
      //   successUrl: `${window.location.origin}`,
      //   cancelUrl: window.location.origin,
      //   submitType: "donate",

      // })
      // .then(function (result) {
      //   if (result.error) {
      //     // errorMessage.textContent = result.error.message;
      //   }
      // });
  // });
}

const onFormSubmit = (event) => {
  event.preventDefault()
  const rt = window.grecaptcha.getResponse()
}


const formatter = new Intl.NumberFormat('sfb', {
  style: 'currency',
  currency: 'EUR'
})


export default ({
  subheading = "Prijzen",
  heading = "Flexibele prijzen.",
  description = "Je kan kiezen om een of meteen meerdere gezinnen te helpen.",
  plans = null,
  primaryButtonText = "Doneer nu",
  planDurations = [
    {
      text: "1 lid",
      switcherText: "1 Gezin",
    },
    {
      text: "5 leden",
      switcherText: "5 Gezinnen",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "1 Gezin",
      durationPrices: ["€ 4,50"],
      mainFeature: "Maaltijdbox",
      features: ["Pasta", "Pastasaus", "Melk", "Olie", "Suiker", "Chocokoekjes"],
      quantity: 1
    },
    {
      name: "5 Gezinnen",
      durationPrices: ["€ 22,50"],
      mainFeature: "Maaltijdbox",
      features: ["Pasta", "Pastasaus", "Melk", "Olie", "Suiker", "Chocokoekjes"],
      quantity: 5,
      featured: true
    }
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rt, setRt] = useState("")
  const [donateButtonText, setDonateButtonText] = useState("Doneer")

  const toggleModal = (quantity) => {setModalIsOpen(!modalIsOpen); setPrice(4.50); setCount(quantity || 1); };

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(4.50);
  const [total, setTotal] = useState(0);

  const [selected, setSelected] = useState(true);
  const toggleSelected = () => {setSelected(!selected)}


  const handleSubmit = (event) => {
    setDonateButtonText("Verwerken...");
    event.preventDefault();
    // Execute the reCAPTCHA when the form is submitted
    recaptchaRef.current.execute();
  };

  const recaptchaRef = React.createRef();


  useEffect(() => {
    // Update the document title using the browser API
    const script = document.createElement('script')
    script.src = "https://www.google.com/recaptcha/api.js";
    window.onSubmit = (e) => setRt(e);
    document.body.appendChild(script);
  });

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        {/* <PlanDurationSwitcher>
          {planDurations.map((planDuration, index) => (
            <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
          ))}
        </PlanDurationSwitcher> */}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                  {/* <span className="slash"> / </span>
                  <span className="duration">{planDurations[activeDurationIndex].text}</span> */}
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton plan={index} quantity={plan.quantity} onClick={() => {toggleModal(plan.quantity);} }>{primaryButtonText}</BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={() => toggleModal(1)}
          shouldCloseOnOverlayClick={true}
        >
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <CloseModalButton onClick={() => toggleModal(1)}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content" className="opacity-100">
            <div tw="flex">
              <form id="form" tw="flex-auto" action="/api/createOrder" method="POST">
                <div tw="flex flex-wrap">
                  <div tw="w-full flex flex-auto justify-center m-auto text-sm font-medium text-gray-500 mt-2">
                    Maak uw keuze
                  </div>
                </div>
                <div tw="flex justify-around mt-6">
                    <SelectablePlan plan="Groot" prijs={4.50} count active={true}/>
                </div>

                <div tw="flex flex-wrap">
                  <div tw="w-full flex flex-auto justify-center m-auto text-sm font-medium text-gray-500 mt-2">
                    Aantal
                  </div>
                </div>
                <>
                <div
                    tw="flex flex-row border h-12 w-48 md:h-10 md:w-32 rounded-lg border-gray-400 relative m-auto mt-4 mb-6"
                  >
                    <button
                      type="button"
                      tw="font-semibold border-r bg-yellow-500 hover:bg-yellow-600 text-white border-gray-400 h-full w-20 flex rounded-l focus:outline-none cursor-pointer"
                      onClick={() => setCount(Math.max(count - 1, 1))}
                      style={{touchAction: 'manipulation'}}
                    >
                      <span tw="m-auto">-</span>
                    </button>
                    <input
                      type="hidden"
                      tw="md:p-2 p-1 text-xs md:text-base border-gray-400 focus:outline-none text-center"
                      readonly
                      name="aantal"
                      id="aantal"
                      value={count}
                    />
                    <div
                      tw="bg-white w-24 text-base flex items-center justify-center cursor-default"
                    >
                      <span>{count}</span>
                    </div>

                    <button
                      type="button"
                      tw="font-semibold border-l  bg-yellow-500 hover:bg-yellow-600 text-white border-gray-400 h-full w-20 flex rounded-r focus:outline-none cursor-pointer"
                      onClick={() => setCount(count + 1)}
                      style={{touchAction: 'manipulation'}}
                    >
                      <span tw="m-auto">+</span>
                    </button>
                  </div>
                </>
                <div tw="flex flex-wrap mt-5 mb-5 mx-auto justify-center" style={{width: '304px'}}>
                  <div tw="flex-auto text-xl font-semibold">
                    Totaal
                  </div>
                  <div tw="text-xl font-semibold text-gray-500">
                    {formatter.format(Math.round(count*price*100)/100)}
                  </div>
                </div>
                <div class="text-center">
                  <div className="g-recaptcha" data-sitekey="6Lcqh4sbAAAAAFP_Q7dlpDN0bJ6U-_smv_4Wm6lD" data-callback="onSubmit" style={{margin: '1rem auto', width: '304px'}}></div>
                </div>
                <div tw="flex mb-4 text-sm font-medium">
                  <div tw="flex-auto flex justify-center">
                    <BuyNowButton type="submit" disabled={count==0 || !rt} tw="max-w-48" onSubmit={handleSubmit}>{donateButtonText}</BuyNowButton>
                  </div>
                </div>
                <p tw="text-sm text-gray-500 text-center">
                  Uw donatie wordt integraal benut voor het aankoop van de basismiddellen.
                </p>

                <input id="rt" name="rt" value={rt} type="hidden"/>
              </form>
            </div>
          </div>
          
        </StyledModal>
    </Container>
  );
};
